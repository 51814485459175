<template>
  <div>
    <vue-header title="AI中医评估" isReturn="true"/>
    <div class="confirmAssess-container">
      <div class="confirmAssess-header">
        <img src="../../../images/sys.png" alt=" ">
      </div>
      <div class="confirmAssess-content">
        <div
          v-for="(item, index) in content"
          :key="index"
          class="content-item"
        >
          <img :src="item.img" alt=" "/>
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.subtitle }}</div>
        </div>
      </div>
      <div class="confirmAssess-step">
        <p>健康评估流程</p>
        <div class="step-container">
          <img :src="item" alt=" " v-for="(item, index) in stepsImage" :key="index"/>
        </div>
      </div>
      <div class="confirmAssess-bottom">
        <p class="tips">点击“开始评估”，即表示您同意将照片上传至系统后台,用于健康分析</p>
        <van-button type="primary" class="button-type" @click="confirmAssessFun" v-if="go">开始评估</van-button>
        <van-button type="primary" class="button-thin" v-else>开始评估</van-button>
      </div>
    </div>
  </div>
</template>
<script>
  import vueHeader from '@/components/header' 
  import { mapActions , mapState , mapMutations } from 'vuex';
  import { getDomain } from '@/utils';
  export default {
    name: "confirmAssess",
    components:{
      vueHeader
    },
    created:function(){
        let flow = JSON.parse(sessionStorage.getItem('flow'));
        //参数
        let assess = {
          server_type:3,
          user_id:0,
          yao_account_id:0,
          sys_company_id:0
        }

        if(this.$route.query.user_id){
            assess.user_id = this.$route.query.user_id;
        }
        if(this.$route.query.returnUrl){
            sessionStorage.setItem('returnUrl',this.$route.query.returnUrl);
        }
        if(this.$route.query.yao_account_id){
            assess.yao_account_id = this.$route.query.yao_account_id;
        }
        if(this.$route.query.sys_company_id){
            assess.sys_company_id = this.$route.query.sys_company_id;
        }
        if(this.$route.params.id){
          assess.server_type = this.$route.params.id;
        }

        let device = sessionStorage.getItem('device');
        let domain = getDomain();
        if((flow && (device=='android' || device=='windows'))||(domain=='localhost' && !this.$route.params.id)||(domain=='192' && !this.$route.params.id)){
            this.change({
              key:'go',
              value:true
            })
        }else{
          if(this.$route.params.id){
            this.axiosData({
              ...assess,
              server_type:this.$route.params.id
            })
          }
        }

        let returns = this.$route.query.return;        
        if(returns){
            sessionStorage.setItem('return',returns);
        }

        //红包
        let red = this.$route.query.red;
        let uid = this.$route.query.uid;
        if(uid){
          let obj = {uid};
          if(red){
            obj.red = red;
          }
          sessionStorage.setItem('red',JSON.stringify(obj));
          sessionStorage.removeItem('equipment');
        }
        
        //如果有手机号
        let phone = this.$route.query.phone;
        if(phone){
            sessionStorage.setItem("phone",phone);
            this.axiosTel({
                data:{
                    company_id:assess.sys_company_id,
                    phone:phone,
                    name:'',
                    birthday:''
                },
                _this:this,
                func:(data)=>{
                    assess.user_id = data.user_id;
                    sessionStorage.setItem('assess',JSON.stringify(assess));
                }
            })
        }else{
            sessionStorage.setItem('assess',JSON.stringify(assess));
        }
        
        this.checkData();

        
    },
    data () {
      return {
        content: [
          {
            img: require("../../../images/pick.png"),
            title: "专业",
            subtitle: "国家科技部和中医药管理局重大研发项目“中医四诊现代化”系列成果转化",
          },
          {
            img: require("../../../images/nao.png"),
            title: "智能",
            subtitle: "浙江大学人工智能研究所合作开发",
          },
          {
            img: require("../../../images/safe.png"),
            title: "安全",
            subtitle: "阿里云部署",
          },
        ]
      };
    },
    computed:{
      ...mapState('confirmAssess',['go','stepsImage']),
    },
    methods:{
      confirmAssessFun(){
        // this.axiosData({})
        let flow = sessionStorage.getItem('flow');
        let flow_step;
        if(flow){
          flow_step = JSON.parse(flow).flow_info.flow_step;
        }

        if(flow_step==4||flow_step==13||flow_step==14){
          this.$router.push({
              name:'confirmTongue'
          });
        }else{
          this.$router.push({
              name:'confirmToface'
          });
        }
      },
      ...mapMutations ("confirmAssess", ["change"]),
      ...mapActions('confirmAssess',['axiosData','checkData']),
      ...mapActions('device',['axiosTel'])
    }
  };
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'confirmAssess';
</style>
